<template>
    <div>
        <p class="font-size-h5 font-weight-normal my-6 text-muted">{{ $t('ARCHIVE.DOCUMENT.HEADERS.METADATA') }}</p>
        <form novalidate="novalidate" id="st-metadata-form">
            <div class="row row-cols-2">
                <div
                    class="col"
                    v-for="(document,index) in documentMeta"
                    :key="index"
                    >
                    <div class="form-group" v-if="document.name !== metadataParams.manualUploadBehalfStatus">
                        <label>
                            {{document.label}}
                            <span v-if="document.mandatory">*</span>
                        </label>
                        <div v-if="document.type.toLowerCase() === 'text'">
                            <st-input-text
                                v-model="document.value"
                                :name="document.name"
                                :ref="document.name"
                            />
                        </div>
                        <div v-if="document.type.toLowerCase() === 'text_area'">
                            <b-form-textarea
                                v-model="document.value"
                                :name="document.name"
                                :ref="document.name"
                            ></b-form-textarea>
                        </div>
                        <div v-if="document.type.toLowerCase() === 'date'">
                            <st-datepicker
                                v-model="document.value"
                                :name="document.name"
                                @change="validateField(document)"
                                :max="maxDate"
                                :formatOptions="dateFormatOptions"
                            />
                        </div>
                        <div v-if="document.type.toLowerCase() === 'numeric'">
                            <b-form-input
                                type="number"
                                v-model.number="document.value"
                                :name="document.name"
                                :ref="document.name"
                            ></b-form-input>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import KTUtil from '@/assets/js/components/util';
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import { metadataParams } from '@/shared/utils/archive-meta-params';

export default {
    name: 'DocumentMetadataManual',
    props: {
        documentMeta: {
            type: Array,
            required: true
        },
    },
    mounted() {
        const form = KTUtil.getById('st-metadata-form');
        this.fv = formValidation(form, {
            fields: {},
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        });

        this.documentMeta.forEach(metadata => {
            if (metadata.mandatory) {
                this.fv.addField(metadata.name, {
                    validators: {
                        notEmpty: {
                            message: this.$t('VALIDATION.REQUIRED_FIELD'),
                        }
                    }
                });
            }
        });
    },
    data() {
        return {
            metadataParams,
            maxDate: new Date(),
            dateFormatOptions: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }
        };
    },
    methods: {
        validateForm() {
            return this.fv.validate()
            .then(status => {
                if (status === 'Valid') {
                    return this.documentMeta;
                }
            });
        },
        validateField(field) {
            if (field.mandatory) this.fv.validateField(field.name);
        },
    },
}
</script>
