<template>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label>
                    {{ $t('ARCHIVE.DOCUMENT.FIELDS.WORKSPACE') }}
                </label>
                <div>
                    <st-input-text
                        v-model="documentInfo.workspaceName"
                        disabled
                    />
                </div>
            </div>
            <div class="form-group">
                <label>
                    {{ $t('ARCHIVE.DOCUMENT.FIELDS.DOCUMENT_TYPE') }}
                </label>
                <div>
                    <st-input-text
                        v-model="documentInfo.documentTypeName"
                        disabled
                    />
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label>
                    {{ $t('ARCHIVE.DOCUMENT.FIELDS.DOCUMENT_FOLDER') }}
                </label>
                <div>
                    <st-input-text
                        v-model="documentInfo.parentFolderName"
                        disabled
                    />
                </div>
            </div>
            <div class="form-group" v-if="!manualArchive">
                <label>
                    {{ $t('ARCHIVE.DOCUMENT.FIELDS.DOCUMENT_NAME') }}
                </label>
                <div>
                    <st-input-text v-model="documentInfo.documentName" disabled/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DocumentInfo',
    props: {
        documentInfo: {
            type: Object,
            required: true
        },
        manualArchive: {
            type: Boolean,
            default: false
        }
    },
}
</script>
