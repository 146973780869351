<template>
    <div>
        <Loader v-if="isLoading"/>
        <div class="row" v-if="document.documentInfo">
            <div class="col-12 col-md-8">
                <b-card class="st-section">
                    <div class="st-section-body">
                        <p class="font-size-h5 font-weight-bold mb-6">
                            {{ $t('ARCHIVE.DOCUMENT.HEADERS.DETAILS') }}
                        </p>
                        <document-info
                            :documentInfo="document.documentInfo"
                            :manualArchive="!documentId"
                            ref="document-info"
                            />
                        <document-metadata v-if="documentId" :documentMeta="document.docMetadata"/>
                        <document-metadata-manual
                            v-else
                            :documentMeta="document.docMetadata"
                            ref="document-metadata-manual"
                        />
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-md-4">
                <b-card class="st-section">
                    <div class="st-section-body">
                        <document-attachments
                            :documentAttachment="document.docAttachment"
                            :documentId="documentId"
                            :workspace="workspace"
                            ref="document-attachments"
                        />
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import DocumentInfo from './DocumentInfo';
import DocumentMetadata from './DocumentMetadata';
import DocumentMetadataManual from './DocumentMetadataManual';
import DocumentAttachments from './DocumentAttachments';
import mime from 'mime-types';
import { mapGetters, mapActions } from 'vuex';
import { metadataParams } from '@/shared/utils/archive-meta-params';

export default {
    name: 'DocumentBody',
    components: {
        DocumentInfo,
        DocumentMetadata,
        DocumentAttachments,
        DocumentMetadataManual
    },
    props: {
        documentId: {
            type: String,
            default: ''
        },
        folder: {
            type: Object,
            required: true
        },
        workspace: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            metadataParams,
            document: {}
        };
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            archivedDocument: 'archive/document/archivedDocument',
            currentUser: 'auth/currentUser',
        }),
        isLoading() {
            return this.loading['archive/document/getArchivedDocument'] ||
                 this.loading['archive/document/getFolderTypeByDocumentCode'] ||
                 this.loading['archive/document/archiveDocument'] ||
                 this.loading['archive/document/upload'] ;

        }
    },
    async created() {
        if (!this.documentId) {
            const documentType = await this.getFolderTypeByDocumentCode(this.folder.documentTypeCode);
            const docMetadata = documentType.metadataDefinitions.map((document)=> ({
                name: document.name,
                label: document.label,
                type: document.type,
                mandatory: document.mandatory,
                value: '',
            }))
            this.document = {
                documentInfo : {
                    documentName: '',
                    documentCode: documentType.code,
                    documentTypeName: documentType.name,
                    parentFolderName: this.folder.name,
                    workspaceName: this.workspace.name,
                },
                docMetadata,
                docAttachment: []
            }
        } else {
            await this.getDocument({id:this.documentId, workspace: this.workspace});
            this.document = this.archivedDocument;
        }
    },
    methods: {
        ...mapActions({
            getDocument: 'archive/document/getDocument',
            getFolderTypeByDocumentCode: 'archive/document/getFolderTypeByDocumentCode',
            doUpload: 'archive/document/upload',
            doManualArchive: 'archive/document/manualArchive',
        }),
        async submit() {
            const metadata = {};
            const payload = {};

            const docMetadata = await this.$refs['document-metadata-manual'].validateForm();
            const docAttachments =  this.$refs['document-attachments'].getAttachments();

            if (docMetadata) {
                payload.documentTypeCode = this.document.documentInfo.documentCode;
                docMetadata.forEach((document) => {
                    // Empty value(s) will be replace with null
                    const metadataValue = !!document.value ? document.value : null;
                    metadata[document.name] = document.type.toLowerCase() === 'date' && document.value ? new Date(document.value).toISOString() : metadataValue;
                })
                payload.metadata = metadata;
                payload.metadata[metadataParams.manualUploadBehalfStatus] = this.currentUser.email;

                if (docAttachments.length) {
                    let formData = new FormData();
                    for( let i = 0; i < docAttachments.length; i++ ) {
                        let file = docAttachments[i];
                        formData.append('files', file);
                    }
                    this.doUpload(formData)
                    .then((response) => {
                        const headersToken = response.headers['x-file-download-access-token'];
                        const data = response.data;

                        const attachments = data.map(attachment => {
                            const attachmentSource = `${window.VUE_APP_API_URL}/files/${attachment.id}?token=${headersToken}`;
                            const fileExtension = mime.extension(attachment.mimetype);
                            return {
                                attachmentName: `${attachment.filename}.${fileExtension}`,
                                attachmentSourceType: 'URL',
                                attachmentSource
                            }
                        })
                        payload.attachments = attachments;
                        this.doManualArchive(payload)
                        .then(data=> {
                            // TODO: Update once BE is returning different status in case of error (e.g. 40x)
                            if (data.code === 'SUCCESS') {
                                this.$emit('manualArchiveUpdate');
                                this.$notify({
                                    type: 'success',
                                    message: this.$t('ARCHIVE.MESSAGES.SUCCESS'),
                                });
                            }
                            if (data.code === 'ERROR') {
                                this.$notify({
                                    type: 'error',
                                    message: this.$t('ARCHIVE.MESSAGES.ERRORS'),
                                });
                            }
                        })
                    })
                }
            }
        },
    },
}
</script>
