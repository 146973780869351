<template>
    <div>
        <p class="font-size-h5 font-weight-bold mb-6">{{ $t('ARCHIVE.DOCUMENT.HEADERS.ATTACHMENTS') }}</p>
        <div class="form-group" v-if="documentId">
            <div
                class="d-flex flex-row"
                v-for="(file,index) in documentAttachment"
                :key="index"
                >
                <st-input-text
                    v-model="file.attachmentName"
                    :disabled="viewMode"
                    class="flex-fill"
                />
                <st-button
                    variant="light"
                    class="st-btn-text-primary d-inline"
                    :callback="() => downloadDocumentAttachment(file)"
                >
                    <i class="fa fa-download"></i>
                </st-button>
            </div>
        </div>
        <div v-else>
            <div class="st-files-container">
                <template v-if="documents.length">
                    <div v-for="(doc, index) in documents" :key="index"
                         class="st-file-item">

                        <div class="text-truncate">{{doc.name}}</div>
                        <i class="st-remove-file fas fa-trash" @click="removeFile(index)"></i>
                    </div>
                </template>
                <div v-if="!documents.length">{{$t('ARCHIVE.ATTACHMENTS.EMPTY')}}</div>
                <p class="text-danger my-4" v-if="!hasAttachments">
                    <span class="mr-2"><i class="fas fa-exclamation-triangle text-danger"></i></span>
                    <span>{{$t('ARCHIVE.ATTACHMENTS.EMPTY_WARNING')}}</span>
                </p>
            </div>
            <hr/>
            <div>
                <form novalidate="novalidate" id="st-attachments-form">
                    <div class="form-group">
                        <label>
                            {{$t('ARCHIVE.ATTACHMENTS.LABEL')}}
                        </label>
                        <div>
                            <b-form-file
                                v-model="attachment.file"
                                :file-name-formatter="formatNames"
                                multiple
                                class="mb-4"
                                browse-text="Alege fișier"
                                placeholder=""
                                name="file"
                                ref="file"
                            ></b-form-file>
                        </div>
                    </div>
                </form>
                <st-button
                    variant="light"
                    block
                    class="st-btn-text-primary"
                    :callback="addAttachment"
                >
                    {{$t('ARCHIVE.ATTACHMENTS.ADD_BUTTON')}}
                </st-button>
            </div>
        </div>
    </div>
</template>

<script>
import KTUtil from '@/assets/js/components/util';
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import { mapActions } from 'vuex';
export default {
    name: 'DocumentAttachments',
    props: {
        documentAttachment: {
            type: Array,
            required: true
        },
        documentId: {
            type: String,
            required: true
        },
        workspace: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            viewMode: true,
            documents: [],
            attachment: {
                file: null
            },
            hasAttachments: true
        };
    },
    mounted() {
        if (!this.documentId) {
            const form = KTUtil.getById('st-attachments-form');
            this.fv = formValidation(form, {
                fields: {
                    file: {
                        validators: {
                            notEmpty: {
                                message: this.$t('VALIDATION.REQUIRED_FIELD'),
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    bootstrap: new Bootstrap(),
                },
            });
        }
    },
    methods: {
        ...mapActions({
            doDownloadDocumentAttachment: 'archive/document/downloadDocumentAttachment',
        }),
        downloadDocumentAttachment(file) {
            const params = {
                workspaceRealName: this.workspace.realName,
                archivedDocumentId: this.documentId,
                attachmentName: file.attachmentName
            }
            this.doDownloadDocumentAttachment(params)
            .then( response => {
                const contentType = response.headers['content-disposition'].split(';filename=');
                const filename = contentType[1];
                let blob = new Blob([response.data], { type: 'application/octet-stream' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            })
        },
        addAttachment() {
            this.fv.validate()
            .then(status => {
                if (status === 'Valid') {
                    this.documents = [...this.documents, ...this.attachment.file]
                    this.attachment.file = null;
                    this.hasAttachments = true;
                    this.fv.resetForm(true)
                }
            });
        },
        removeFile(index) {
            this.$delete(this.documents, index);
        },
        formatNames(files) {
            return files.length === 1 ? files[0].name : `${files.length} ${this.$t('ARCHIVE.ATTACHMENTS.PLACEHOLDER')}`
        },
        getAttachments() {
            this.hasAttachments = this.documents.length;
            return this.documents;
        }
    },
}
</script>
