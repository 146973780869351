<template>
    <div>
        <p class="font-size-h5 font-weight-normal my-6 text-muted">{{ $t('ARCHIVE.DOCUMENT.HEADERS.METADATA') }}</p>
        <div class="row row-cols-2">
            <div
                class="col"
                v-for="(document,index) in documentMeta"
                :key="index"
                >
                <div class="form-group">
                    <label>
                        {{document.label}}
                    </label>
                    <st-input-text
                        v-model="document.value"
                        :disabled="viewMode"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DocumentMetadata',
    props: {
        documentMeta: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            viewMode: true
        };
    },
}
</script>
